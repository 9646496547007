<template>
  <div class="popup" :style="[open ? {'display': 'flex'} : {'display': 'none'}]">
    <div class="window">
      <div class="cross" @click="$emit('close_popup')"></div>
      <h1 class="title" v-if="title">{{ title }}</h1>
      <div class="text" v-if="description">
        <p class="description" v-html="description"></p>
      </div>
      <div 
        class="places-gallery"
        v-if="gallery && gallery.length"
        :style="[this.getScreenWidth() > 1200 ? {'width': this.width}:{'width': '95vw'}]"
        >
        <div class="slides">
          <vueper-slides
              :style="[this.getScreenWidth() > 1200 ? {'width': this.width, 'border-radius': '10px'}:{'width': '95vw', 'border-radius': '10px'}]"
              class="no-shadow"
              ref="vueperslides1"
              :touchable="false"
              fade
              :autoplay="false"
              :bullets="false"
              :fixed-height="[miniatures? '50vh': '80vh']">
              <template #arrow-left>
                  <button class="arrow_left"></button>
              </template>
              <template #arrow-right>
                  <button class="arrow_right"></button>
              </template>
              <vueper-slide
                  style="border-radius: 10px; cursor: pointer;"
                  v-for="(slide, i) in gallery"
                  :key="i"
                  :image="this.optimize_img(slide.image, 1000)"
                  @click="this.openInNewTab(slide.image)">
              </vueper-slide>
          </vueper-slides>
        </div>
      </div>
      <div class="miniatures" v-if="gallery.length && miniatures">
          <div class="miniature" v-for="(slide, i) in gallery" :key="i" @click="$refs.vueperslides1.goToSlide(i)">
              <img :src="this.optimize_img(slide.image, 100)">
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'
  export default {
    name: 'PopUpWindow',
    components: { VueperSlides, VueperSlide },
    props: {
      open: Boolean,
      place: Object,
      title: String,
      description: String,
      miniatures: {
        type: Boolean,
        default: true
      },
      width: {
        type: String,
        default: "580px"
      },
      gallery: Array
    },
    data() {
    },
    methods: {
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.miniatures {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 780px;
    gap: 5px;
    justify-content: flex-start;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
}
.miniature {
    border-radius: 5px;
    cursor: pointer;
    display: block;
    overflow: hidden;
}
.miniature img{
    border-radius: 5px;
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: center center;
}
button[class^="arrow"]{
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    width: 40px;
    height: 617px;
    background-size: 40px 40px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-image: url("./img/arrow.png");
    border: none;
    position: absolute;
}
button[class^="arrow"]:hover{
    background-image: url("./img/arrow_yellow.png");
}
button[class^="arrow"]:disabled{
    cursor: default;
    background-image: url("./img/arrow.png");
    opacity: 1;
}
.arrow_right {
    transform: scaleX(-1);
    right: 0px;
}
.places-gallery {
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popup {
  position: absolute;
  right: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  top: 100px;
  z-index: 2000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.window {
  padding: 20px;
  width: 980px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  z-index: 2500;
}
.cross {
  width: 47px;
  height: 47px;
  background-image: url("./img/cross.png");
  background-size: contain;
  align-self: end;
  cursor: pointer;
  background-repeat: no-repeat;
}
.text{
  margin-left: 50px;
}
.title {
  margin-left: 50px;
  font-family: Manrope;
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 25px;
}
.description {
  margin-top: 40px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 75%;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {

  .window {
    padding: 0px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    z-index: 2500;
    max-width: 100vw;
  }

  .places-gallery {
    max-width: 50vw;
  }

  .miniatures {
    display: none;
  }

  .description {
    max-width: 95%;
  }

}
</style>
